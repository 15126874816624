import { Tooltip } from './components/abatable-ui/Tooltip'
import { GeneralErrorBoundary } from './components/ErrorBoundary'
import { useToast } from './components/ui/Toaster'
import styles from './globals.css?url'
import { cn, combineHeaders } from './lib/utils'
import { toastService } from './services/toast.server'

import { redirect } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useLocation,
  useNavigation,
  useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { posthog } from 'posthog-js'
import { useEffect } from 'react'

import { Toaster } from '~/components/ui/Toast'

import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node'

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap',
  },
]

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const upgradeToHttps = (request: Request) => {
    // upgrade people to https automatically

    const url = new URL(request.url)
    const hostname = url.hostname
    const proto = request.headers.get('X-Forwarded-Proto') ?? url.protocol

    url.host =
      request.headers.get('X-Forwarded-Host') ??
      request.headers.get('host') ??
      url.host
    url.protocol = 'https:'

    if (proto === 'http' && hostname !== 'localhost') {
      return redirect(url.toString(), {
        headers: {
          'X-Forwarded-Proto': 'https',
        },
      })
    }
  }

  upgradeToHttps(request)
  const { toast, headers: toastHeaders } = await toastService.get(request)

  //get the route we are on and log it
  const route = request.url
  console.log(`Curent route: ${route}`)

  return json(
    {
      toast,
    },
    {
      headers: combineHeaders(toastHeaders),
    },
  )
}

type HubSpotCall = ['setPath', string] | ['trackPageView']

type HubSpot = {
  push: (callParam: HubSpotCall) => void
}

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    _hsq?: HubSpot
  }
}

export const BODY_BACKGROUND_COLOR = 'bg-brand-green-50'

function Document({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className={`h-full overflow-x-hidden`}>
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
        <script
          src="https://js-eu1.hs-scripts.com/25465925.js"
          id="hs-script-loader"
          key="hs-script-loader"
          type="text/plain"
          data-cookieconsent="statistics"
          async
          defer
        />
      </head>
      <body className={BODY_BACKGROUND_COLOR}>
        {children}
        <ScrollRestoration />
      </body>
    </html>
  )
}

function App() {
  const data = useLoaderData<typeof loader>()
  useToast(data.toast)

  const location = useLocation()

  useEffect(() => {
    window._hsq ||= new Array<HubSpotCall>()
    window._hsq.push(['setPath', location.pathname])
    window._hsq.push(['trackPageView'])
    posthog.capture('$pageview')
  }, [location])

  return (
    <Document>
      <Tooltip.Provider>
        <div className="flex-1">
          <Outlet />
        </div>
      </Tooltip.Provider>
      <NavigationProgressBar />
      <Toaster closeButton position="top-center" />
      <Scripts />
    </Document>
  )
}

export default withSentry(App)

function NavigationProgressBar() {
  const transition = useNavigation()
  const active = transition.state !== 'idle'

  return (
    <div
      role="progressbar"
      aria-hidden={!active}
      aria-valuetext={active ? 'Loading' : undefined}
      className="fixed inset-x-0 top-0 z-50 h-1 animate-pulse"
    >
      <div
        className={cn(
          'h-full bg-brand-blue-500 transition-all duration-500 ease-in-out',
          active ? 'w-full' : 'w-0 opacity-0 transition-none',
        )}
      />
    </div>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)

  return (
    <Document>
      <GeneralErrorBoundary />
    </Document>
  )
}
