import { TriangleAlert } from 'lucide-react'
import { Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group"
      icons={{
        error: <TriangleAlert className="h-5 w-5 mr-2 text-error-400" />,
      }}
      toastOptions={{
        duration: props.duration ?? 5000,
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-white group-[.toaster]:text-primary-500 group-[.toaster]:border-grey-200 group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-grey-400',
          actionButton:
            'group-[.toast]:bg-primary-500 group-[.toast]:text-white',
          cancelButton: 'group-[.toast]:bg-error-400 group-[.toast]:text-white',
          error: 'group-[.toaster]:bg-white group-[.toaster]:border-error-400',
          closeButton: 'text-black bg-white',
        },
      }}
      {...props}
    />
  )
}

export { Toaster }
